import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./payment.scss";
import { useSelector } from "react-redux";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import Succsess from "../../components/payment/Succsess";
import CheckoutForm from "../../components/checkOut/CheckoutForm";

const Payment = () => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("pending");

  const [success, setSuccess] = useState("");
  const [
    destination_address_mobile_number,
    setDestination_address_mobile_number,
  ] = useState("");

  const [destination_address_house_no, setDestination_address_house_no] =
    useState("");
  const [destination_address_area, setDestination_address_area] = useState("");
  const [destination_address_landmark, setDestination_address_landmark] =
    useState("");
  const [destination_address_city, setDestination_address_city] = useState("");

  const [customer_reference_number, setCustomer_reference_number] =
    useState("");

  const [showCheckout, setShowCheckout] = useState(false);

  const [sessionId, setSessionId] = useState(null);

  const generateUniqueNumber = () => {
    const uniqueNumber = "NOT-" + Date.now();
    setCustomer_reference_number(uniqueNumber);
  };

  useEffect(() => {
    generateUniqueNumber();
  }, []);

  const products = useSelector((state) => state.cart.products);

  const delivaryAmout = 20;
  const delivaryAmoutLess = 10;
  const noDelivaryAmout = 0;

  const totals = products.reduce(
    (acc, product) => {
      const price = parseFloat(product.price);
      const quantity = parseInt(product.quantity, 10);

      acc.totalPrice += price * quantity;
      acc.totalItems += quantity;

      return acc;
    },
    { totalPrice: 0, totalItems: 0 }
  );

  const netOder = totals.totalPrice;

  const withDelivaryCost = () => {
    if (netOder < 50) {
      const netTotal = netOder + delivaryAmout;
      return netTotal.toFixed(2);
    }
    if (netOder >= 50 && netOder < 75) {
      const netTotal = netOder + delivaryAmoutLess;
      return netTotal.toFixed(2);
    }
    if (netOder >= 75) {
      const netTotal = netOder + noDelivaryAmout;
      return netTotal.toFixed(2);
    } else {
      console.log("all good");
    }
  };

  const deliveryCost = () => {
    if (netOder < 50) {
      return 20;
    } else if (netOder >= 50 && netOder < 75) {
      return 10;
    } else {
      return 0;
    }
  };
  const delivery_cost = deliveryCost();

  const finalCost = withDelivaryCost();

  const productsData = {
    totals: finalCost,
    qut: totals.totalItems,
    fName,
    email,
    price: finalCost,
    name: fName,
    title: fName,
    quantity: totals.totalItems,
  };

  const placeOrder = async (e) => {
    e?.preventDefault();
    const orderData = {
      destination_address_name: fName + " " + lName,
      email,
      products,
      brand: "apronsnlove",
      num_pieces: productsData.qut,
      card_amount: productsData.totals,
      descripon: products.title,
      destination_address_city,
      destination_address_landmark,
      destination_address_area,
      destination_address_building_name: destination_address_house_no,
      destination_address_house_no,
      customer_reference_number,
      destination_address_mobile_number,
      weight: 2,
      delivery_cost,
    };

    localStorage.setItem("orderData", JSON.stringify(orderData));
  };

  const showCheckoutSection = (e) => {
    e.preventDefault();
    setShowCheckout(true);
    placeOrder();
  };

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const sessionId = urlParams.get("session_id");
  //   setSessionId(sessionId);
  // }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    axios
      .get(`/session-status?session_id=${sessionId}`)
      .then((response) => {
        const data = response.data;
        setStatus(data.status);
      })
      .catch((error) => {
        console.error("Error fetching session status:", error);
      });
  }, []);

  // if (status === "complete") {
  //   placeOrder();
  // }

  useEffect(() => {
    const updateOrder = async () => {
      if (status !== "complete") {
        return;
      }
      const savedData = localStorage.getItem("orderData");
      if (savedData) {
        const orderData = JSON.parse(savedData);
        try {
          if (status == "complete") {
            await axios.post("/create_order", orderData);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    updateOrder();
  }, [status]);

  return (
    <div className="max-width">
      {status !== "complete" ? (
        <>
          <div className="payment">
            <div className="pay_info">
              <div className="pro_list">
                <div>
                  <h5>Order summary</h5>
                </div>
                {products.length > 0 ? (
                  <div className="all_pro_list">
                    {products.map((product, index) => (
                      <div className="pro_card" key={index}>
                        <div className="img_wrp">
                          <img
                            src={process.env.REACT_APP_UPLOAD_URL + product.img}
                            alt=""
                          />
                        </div>
                        <div className="info_wrp">
                          <span>
                            <small>{product.title}</small>
                          </span>
                        </div>
                        <div className="info_wrp">
                          <span>
                            <small>Qut:</small> {product.quantity}
                          </span>
                        </div>
                        <div className="info_wrp">
                          <span>
                            <small>AED </small> {product.price.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No products in the cart</p>
                )}
                <div className="total_wrp bg">
                  <span>Have coupon ? </span>
                  <input placeholder="ABC123" type="text" />
                </div>
                <div className="total_wrp">
                  <span>Items Total</span>
                  <span>AED {netOder.toFixed(2)}</span>
                </div>
                <div className="total_wrp">
                  <span>Delivery Charges</span>
                  <span>AED {delivery_cost}</span>
                </div>
                <hr />
                <div className="total_wrp">
                  <h4>Total</h4> <h4>AED {withDelivaryCost()}</h4>{" "}
                </div>
              </div>
              <div className="check_form">
                <div>
                  <strong>Customer details</strong>
                </div>
                <form onSubmit={showCheckoutSection}>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>First name</label>
                      <input
                        required
                        type="text"
                        value={fName}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Last name</label>
                      <input
                        required
                        type="text"
                        value={lName}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>Email</label>
                      <input
                        required
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Phone Number</label>
                      <input
                        required
                        value={destination_address_mobile_number}
                        type="text"
                        onChange={(e) =>
                          setDestination_address_mobile_number(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>City</label>

                      <select
                        name="city"
                        class="form-control save_data"
                        id="city"
                        value={destination_address_city}
                        required="required"
                        onChange={(e) =>
                          setDestination_address_city(e.target.value)
                        }
                      >
                        <option>Select Your City </option>
                        <option value="Abu Dhabi">Abu Dhabi</option>
                        <option value="Dubai">Dubai</option>
                        <option value="Sharjah">Sharjah</option>
                        <option value="Ajman">Ajman</option>
                        <option value="Umm Al-Quwain">Umm Al-Quwain</option>
                        <option value="Fujairah">Fujairah</option>
                        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                        <option value="Al-Ain">Al-Ain</option>{" "}
                      </select>
                    </div>

                    <div className="input_wrp">
                      <label>Address</label>
                      <input
                        required
                        type="text"
                        value={destination_address_area}
                        onChange={(e) =>
                          setDestination_address_area(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>House No. or Name</label>
                      <input
                        required
                        type="text"
                        value={destination_address_house_no}
                        onChange={(e) =>
                          setDestination_address_house_no(e.target.value)
                        }
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Landmark</label>
                      <input
                        required
                        type="text"
                        value={destination_address_landmark}
                        onChange={(e) =>
                          setDestination_address_landmark(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="pay_btn">
                    <button className="checkout">Continue</button>
                  </div>
                  <small style={{ color: "#FF0000", fontSize: "14px" }}>
                    <IoIosWarning color="red" /> Note: Please do not close this
                    page until your order is confirmed.
                  </small>
                </form>
              </div>
            </div>
            <div className="pay_card">
              {showCheckout && <CheckoutForm productsData={productsData} />}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <Succsess />
          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
