import React from "react";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./Home.scss";

import sample from "../../img/hero.mp4";
import one from "../../img/one.webm";
import two from "../../img/two.webm";
import three from "../../img/three.webm";
import backImg from "../../img/mission-img.webp";
import { Link } from "react-router-dom";
import Newsletter from "../../components/Newsletter/Newsletter";
import Popupdv from "../../components/popup/Popup";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="max-width">
      <div className="home">
        <div className="hero_wrappe">
          <div className="video">
            <video className="videoTag" autoPlay loop muted>
              <source src={sample} type="video/mp4" />
            </video>
          </div>
          {
            <div className="heading">
              <p>
                Why shouldn’t healthy also be delicious? Aprons ‘N’ Love says it
                should, and now we’re gonna prove it.
              </p>
            </div>
          }
        </div>
        <div className="heading-two">
          <h1>
            Aprons ‘N’ Love. Taste the quality. Love the flavour. <br /> Let
            your creativity run wild.
          </h1>
        </div>

        <FeaturedProducts type="featured" />

        <div className="product_info_section_one">
          <div className="text">
            <h1 style={{ color: "#003087" }}>Almond flour</h1>
            <div className="strong">
              <strong>No ordinary flour – this stuff’s extrordinary</strong>
            </div>

            <p>
              Our almond flour is your creative gateway into healthy, tasty
              baking nirvana. Made from ground blanched almonds and loaded with
              nutritional value, it contains vitamin E and other antioxidants to
              help keep an active body in peak health. Best of all, compared to
              other gluten-free flour options available, it has more protein, is
              easier to bake with, and is far tastier. Can’t argue with that!
            </p>
            <div className="btn">
              <Link to="/nut-butter" onClick={scrollToTop}>
                <button style={{ backgroundColor: "#003087" }}>
                  Learn More….
                </button>
              </Link>
            </div>
          </div>

          <div className="img">
            <img src={require("../../img/pro_info_img.webp")} />
          </div>
        </div>

        <div
          className="home_mission"
          style={{
            backgroundImage: `url(${backImg}) `,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1>CORPORATE VALUES</h1>
          <strong>
            Our brands combine superior Notions of the world; so do the
            principles we live by
          </strong>
          <br />
          <p style={{ color: "white" }}>
            Passion is the force that drives us; reason is what steers our
            endeavours. We put our hearts and our minds in every little detail
            of the business to achieve the best results. We are passionate about
            our brands, our products, our customers, and our people, because
            they are the reason we are here, and that is what makes us
            successful.
          </p>
          <div className="card_button">
            <Link to="/about" onClick={scrollToTop}>
              <button>Read More….</button>
            </Link>
          </div>
        </div>

        <div className="product_info_section_one">
          <div className="img">
            <img src={require("../../img/chips_box.webp")} />
          </div>
          <div className="text">
            <h1>Chocolate chips</h1>
            <div className="strong">
              <strong>
                There is a chocolate chip universe, and your attendance is
                requested.
              </strong>
            </div>

            <p>
              Our chocolate chips are super-powered nuggets of pleasure and
              baking simplicity. They’re all- natural, gluten-free, vegetarian
              and preservative-free. Craving something a little exotic? Try our
              Ruby Chocolate Chips. Naturally pink, they contain no artificial
              colorants, preservatives or fruit flavorings, but will unleash a
              subtle explosion of ambrosial ecstasy that elevates whatever
              they’re added to. All our chips have a shelf life of 365 days, a
              cocoa content of over 50%, and a smile quotient of approximately
              110%.
            </p>
            <div className="btn">
              <Link to="/chocolate-chips" onClick={scrollToTop}>
                <button button style={{ backgroundColor: "#f19c26" }}>
                  Learn More….
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="testimonial">
          <h1>What out customer say about us</h1>
          <br />
          <br />
          <div className="test_in">
            <div className="test_vido">
              <div className="video">
                <video className="videoTag" controls>
                  <source src={one} type="video/webm" />
                  <source src={one} type="video/mp4" />
                </video>
              </div>
            </div>

            <div className="test_vido">
              <div className="video">
                <video className="videoTag" controls>
                  <source src={two} type="video/webm" />
                </video>
              </div>
            </div>

            <div className="test_vido">
              <div className="video">
                <video className="videoTag" controls>
                  <source src={three} type="video/webm" />
                </video>
              </div>
            </div>
          </div>
        </div>

        <Newsletter />
      </div>
    </div>
  );
};

export default Home;
