import React from "react";
import "./Footer.scss";
import Copyright from "../Copyright/Copyright";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import Pay_Icon from "../PaymentIcon/Pay_Icon";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer_wrap">
      <div className="footer">
        <div className="footer_my_container">
          <div className="footer_section_one">
            <h1>
              Follow us <span1>@Aprons n love</span1>
            </h1>
            <div className="social_icon">
              <a
                style={{ color: "white" }}
                href="https://www.facebook.com/ApronsNLove"
              >
                <FacebookIcon />
              </a>
              <InstagramIcon />
            </div>
          </div>

          <div className="footer_list">
            <div>
              <ul>
                <li>
                  <Link to="/" onClick={scrollToTop}>
                    {" "}
                    <p>Home</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/products/1" onClick={scrollToTop}>
                    {" "}
                    <p>Shop</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/almond-flour" onClick={scrollToTop}>
                    {" "}
                    <p>Almond Flour</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/coconut-sugar" onClick={scrollToTop}>
                    {" "}
                    <p>Coconut Sugar</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/nut-butter" onClick={scrollToTop}>
                    {" "}
                    <p>Nut Butters</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/chocolate-chips" onClick={scrollToTop}>
                    {" "}
                    <p>Chocolate Chips</p>{" "}
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <Link to="/about" onClick={scrollToTop}>
                    {" "}
                    <p>About</p>{" "}
                  </Link>
                </li>

                <li>
                  <Link to="/terms-&-conditions" onClick={scrollToTop}>
                    {" "}
                    <p>T&C</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" onClick={scrollToTop}>
                    {" "}
                    <p>Privacy Policy</p>{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer_section_two">
            <div className="logo">
              <img src={require("../../img/out_line.png")} />
            </div>
            <p>
              The brand essence is built on the idea that everyone should be
              able to enjoy life’s goodness and savour scrumptious delicacies by
              offering a healthy assortment and contributing to the growth of a
              better world. Made with pure, wholesome ingredients and mindful
              blend with the perfect bold taste, our products will offer you all
              what’s needed to celebrate your special occasions and to relish
              whenever the craving takes over.
            </p>
          </div>
        </div>
      </div>
      <Pay_Icon />
      <Copyright />
    </div>
  );
};

export default Footer;
