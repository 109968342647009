import React from "react";
import "./Almond.scss";
import Newsletter from "../../components/Newsletter/Newsletter";
import AldImg from "../../img/helth_banner.webp";
import AldHero from "../../img/Alm_hero.webp";

const Almond = () => {
  return (
    <div className="max-width">
      <div className="almond_flour">
        <div
          className="almond"
          style={{
            backgroundImage: `url(${AldHero})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
          }}
        >
          <h1>
            Excellent <br /> Source of <br /> Healthy fats
          </h1>
        </div>

        <div className="almond_intro">
          <div className="almond_intro_data">
            <h1>DID YOU KNOW ?</h1>
            <strong>
              You can bake with it like you’d bake with your mama’s flour
            </strong>
            <p>
              Almond flour bakes as easily as conventional wheat flour, and it
              packs numerous health benefits. It’s great for your heart, boosts
              energy levels, helps manage blood sugar, lowers blood pressure and
              LDL cholesterol, while helping to keep humans in top form.
            </p>
          </div>
        </div>

        <div className="helth_be">
          <h1>Health Benefits</h1>
          <div className="helth_be_in">
            <div className="helth_icon_con">
              <div className="helth_icon">
                <div className="he_icon">
                  <img src={require("../../img/icon_1.png")} />
                </div>
                <div>
                  <p>BOOSTS HEART HEALTH</p>
                </div>
              </div>
              <div className="helth_icon">
                <div className="he_icon">
                  <img src={require("../../img/icon_2.png")} />
                </div>
                <div>
                  <p>IMPROVES ENERGY LEVELE</p>
                </div>
              </div>
              <div className="helth_icon">
                <div className="he_icon">
                  <img src={require("../../img/icon_3.png")} />
                </div>
                <div>
                  <p>ASSISTS WITH BLOOD SUGAR CONTROL</p>
                </div>
              </div>
            </div>
            <div className="helth_img">
              <img src={require("../../img/pro_info_img.webp")} />
            </div>
            <div className="helth_icon_con">
              <div className="helth_icon">
                <div className="he_icon">
                  <img src={require("../../img/icon_4.png")} />
                </div>
                <div>
                  <p>HELPS DISCOURAGE CANCER FORMATION</p>
                </div>
              </div>
              <div className="helth_icon">
                <div className="he_icon">
                  <img src={require("../../img/icon_5.png")} />
                </div>
                <div>
                  <p>LOWERS BLOOD PRESSURE</p>
                </div>
              </div>
              <div className="helth_icon">
                <div className="he_icon">
                  <img src={require("../../img/icon_6.png")} />
                </div>
                <div>
                  <p>HELPS LOWER LDL CHOLESTEROL</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="almond_ban"
          style={{
            backgroundImage: `url(${AldImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="shop_button">
            <div className="btn">
              <a href="/product/11">SHOP NOW</a>
            </div>
          </div>
        </div>

        <div className="Alm_wrap">
          <div className="Alm_info">
            <div className="Alm_info_head">
              <h1>Why discerning bakers love almond flour</h1>
              <strong>
                You have nothing to lose and much to gain (except maybe weight)
              </strong>
            </div>
            <div className="Alm_info_des">
              <p>
                One baking session with Aprons ‘N’ Love almond flour will
                convince you – even if you’re a died-in-the-wool, old school
                wheat flour aficionado. You use the same preparation procedures,
                get the same fluffy and consistent breads, pastries and cakes,
                but with a ton of added health and taste benefits. Sleep easily
                knowing you’ve served your family something healthier, realer
                and without the hyper-refined mysteries of conventional wheat
                flour.
              </p>
            </div>
          </div>
        </div>

        <Newsletter />
      </div>
    </div>
  );
};

export default Almond;
